import React, { Fragment, Suspense, lazy } from "react";

import { addHocs } from "shared/helpers/Addhocs";
import Notification from "services/Notification";
import Loading from "services/Loading";

import Apollo from "./services/Apollo";
import Auth from "./services/Auth";

import { Route, Switch } from "react-router";
import { HashRouter as Router } from "react-router-dom";

const Main = lazy(() => import("layouts/Main"));

const App = () => {
  return (
    <Fragment>
      <Notification />
      {/* <Modal /> */}
      <Router>
        <Suspense fallback={<Loading />}>
          <Switch>
            {/* <Route path="/landing" component={Hero} /> */}
            {/* <Route path="/auth" component={Auth} /> */}
            <Route path="/">
              <Main />
            </Route>
            {/* <Route path="/" component={Admin} /> */}

            {/* <Route component={NotFound} /> */}
          </Switch>
        </Suspense>
      </Router>
    </Fragment>
  );
};
export default addHocs(App, [Apollo, Auth]);
