import React from 'react';
import ReactDOM from 'react-dom';
import App from 'app/index.js';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "assets/main.css";
import * as serviceWorker from './serviceWorker';

Sentry.init({
    dsn: "https://c123c8c6e0ee4413ab8f59c7e31db6e0@o377703.ingest.sentry.io/5623343",
    integrations: [new Integrations.BrowserTracing()],
  
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
